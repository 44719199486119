<template>
    <div class="comp-select">
        <input type="text" v-model.trim="comp.companyname" @keydown="comp.doSearch"  @change="comp.doSearch" class="input-txt" placeholder="검색어 입력 후 엔터를 눌러주세요">
        <div v-if="comp.is_selected==false" class="comp-list">
            <div class="comp-item" v-for="(irow, i) in comp.list" :key="i" @click="comp.doSelect(irow)">
                <!-- <span class="red">{{ irow.company_like }}</span>{{ irow.company_etc }} -->
                {{ irow.title_s }}<span class="red">{{ irow.title_m }}</span>{{ irow.title_e }}
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotCompany',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        companyname: {
            type: String,
            default: "",
        },
        bizno: {
            type: String,
            default: "N",
        },
        address: {
          type: String,
          default: "",
        },
        zipcode: {
          type: String,
          default: "",
        },
        address_sub: {
          type: String,
          default: "",
        }
    },
    emits: [ 'update:modelValue', 'update:companyname', 'change', 'update:bizno', 'update:address', 'update:zipcode', 'update:address_sub' ],
    setup(props, { emit }) {
        const toast = useToast()

        const comp = reactive({
            is_selected : true,
            idx_company : 0,
            companyname : "",
            list : [], total : 0,
            temp_list : [],
            find_company : "",
            address :"",
            zipcode : "",
            address_sub: "",

            doSearchInfo : () => {
                let params = {
                    idx : comp.idx_company
                };
                axios.get("/rest/component/getCompanyInfo", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.companyname = res.data.kname;
                        comp.zipcode = res.data.zipcode;
                        comp.address = res.data.addr;
                        comp.address_sub = res.data.addr_sub;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                if(comp.companyname.trim()=="" && comp.find_company.trim() == comp.companyname.trim()) {
                    comp.is_selected = true;
                    comp.idx_company = 0;
                    comp.companyname = "";
                    emit('update:modelValue', comp.idx_company);
                    emit('update:companyname', comp.companyname);

                    emit('update:zipcode', comp.zipcode);
                    emit('update:address', comp.address);
                    emit('update:address_sub', comp.address_sub);
                    if(props.bizno == "Y") {
                        emit('update:bizno', "");
                    }
                    emit('change');
                    return;
                }
                // if(comp.find_company.trim() == comp.companyname.trim()) {
                //     return;
                // }
                if(comp.temp_list.length>0 && comp.find_company.trim()!='' && comp.companyname.trim().indexOf(comp.find_company.trim())===0) {
                    comp.list = [];
                    let find = comp.companyname.trim().toLowerCase();
                    for(let i=0;i<comp.temp_list.length;i++) {
                        let s = comp.temp_list[i].kname.trim().toLowerCase().indexOf(find);
                        console.info(s);
                        if(s>=0) {
                            let l = comp.companyname.trim().length;
                            let title = comp.temp_list[i].kname.toLowerCase();
                            comp.temp_list[i].title_s = comp.temp_list[i].kname.substr(0, s);
                            comp.temp_list[i].title_m = comp.temp_list[i].kname.substr(s, l);
                            comp.temp_list[i].title_e = comp.temp_list[i].kname.substr(s+l, title.length-(s+l));

                            comp.list.push(comp.temp_list[i]);
                        }
                    }
                    if(comp.list.length>0) {
                        comp.is_selected = false;
                    }
                    return;
                }
                comp.find_company = comp.companyname.trim();
                let params = {
                    cname : comp.companyname
                };

                if( params.cname.length < 2 ){
                    comp.is_selected = true;
                    comp.list = [];
                    return;
                }

                emit('update:companyname', comp.companyname);

                axios.get("/rest/component/getCompanyList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.list  = res.data.list;
                        comp.total = res.data.total;

                        let companyname = comp.companyname.toLowerCase();

                        for(let i=0; i<comp.list.length; i++){
                            let irow = comp.list[i];

                            // irow.company_like = comp.companyname;
                            // irow.company_etc  = irow.kname.replace(comp.companyname, '');

                            let title = irow.kname.toLowerCase();
                            let s = title.indexOf(companyname);
                            let l = companyname.length;

                            if(s<0) {
                                irow.title_s = irow.kname;
                                irow.title_m = "";
                                irow.title_e = "";
                            } else {
                                irow.title_s = irow.kname.substr(0, s);
                                irow.title_m = irow.kname.substr(s, l);
                                irow.title_e = irow.kname.substr(s+l, title.length-(s+l));
                            }

                            comp.list[i] = irow;
                            comp.temp_list[i] = irow;
                        }

                        if( comp.total > 0 ) comp.is_selected = false;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSelect : (o) => {
                comp.is_selected = true;
                comp.idx_company = o.idx;
                comp.companyname = o.kname;
                comp.zipcode = o.zipcode;
                comp.address =o.addr;
                comp.address_sub = o.addr_sub;
                emit('update:modelValue', comp.idx_company);
                emit('update:companyname', comp.companyname);
                emit('update:zipcode', comp.zipcode);
                emit('update:address', comp.address);
                emit('update:address_sub', comp.address_sub);
                if(props.bizno == "Y") {
                    emit('update:bizno', o.bizno);
                }
                emit('change');
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_company = props.modelValue;
            comp.companyname = props.companyname;

            if( comp.idx_company > 0 ){
                comp.doSearchInfo();
            }else if(comp.companyname != ''){
                comp.doSearch();
            }
        });

        watch(() => props.modelValue, (newValue) => {
            if(comp.idx_company != newValue) {
                comp.idx_company = newValue;
                comp.doSearchInfo();
            }
        });
        watch(() => props.companyname, (newValue) => {
            if(comp.companyname != newValue) {
                comp.companyname = newValue;
                comp.doSearch();
            }
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    position:relative; display: inline-block; height:30px;
    .input-txt {
        width: 100%; height:30px; vertical-align: top;
    }
    .comp-list {
        position: absolute; top:30px; left:0; width:100%; max-height:200px; overflow:hidden; overflow-y:auto; background-color:#fff; border:1px solid #c8c8c8; padding:5px 8px; z-index: 2;
        .comp-item {
            line-height:30px; cursor: pointer; border-bottom:1px solid #c8c8c8; white-space: nowrap;
            &:hover {
                background-color: #efefef;
            }
            &:last-child {
                border-bottom-width: 0px;
            }
            .red {
                color:#ff854a;
            }
        }
    }
}
</style>