<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="출고현황 수정"></CarrotTitle>
        <div class="board">
            <div v-if="mod.is_loaded" class="mt-40">
                <div>

                    <div v-for="(irow, i) in mod.info.books" :key="i" class="dp-table w-100per mb-10">
                        <div class="w-100per">
                            <table class="table-row">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="100">
                                    <col width="140">
                                    <col width="100">
                                    <col width="140">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th><span class="txt-red">*</span> 교재정보</th>
                                        <td>{{ irow.bookname }}</td>
                                        <th>수량</th>
                                        <td>
                                            <div v-if="mod.info.state=='DONE'" class="txt-right">{{ irow.qty }} 권</div>
                                            <input type="text" v-else v-model.trim.number="irow.qty" @change="mod.calcPrice(i)" class="w-100per txt-right" maxlength="5">
                                        </td>
                                        <th>금액</th>
                                        <td class="txt-right">{{ irow.price_txt }} 원</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table class="table-row mt-40">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="150">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>요청자</th>
                                <td>
                                    {{ mod.info.staff_ename }}({{ mod.info.staff_kname }})
                                </td>
                                <th>등록일</th>
                                <td>
                                    {{ mod.info.wdate }}
                                </td>
                            </tr>
                            <tr>
                                <th>출고현황</th>
                                <td colspan="3">
                                    {{ mod.info.state_txt }}
                                </td>
                            </tr>
                            <tr>
                                <th>내부/외부</th>
                                <td colspan="3">
                                    {{ mod.info.btype_txt }}
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 용도</th>
                                <td colspan="3">
                                    <span v-if="mod.info.state=='DONE'">완료</span>
                                    <select v-else v-model="mod.info.utype" class="w-200px">
                                        <option value="">선택</option>
                                        <option v-for="(irow, i) in mod.utype_list" :key="i" :value="irow.code">{{ irow.name }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사명</th>
                                <td colspan="3">
                                    <span v-if="mod.info.state=='DONE'">{{ mod.info.company_kname }}</span>
                                    <template v-else>
                                        <carrot-company v-if="mod.is_loaded" v-model="mod.info.idx_company" class="w-400px"></carrot-company>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 과정명</th>
                                <td colspan="3">
                                    <span v-if="mod.info.state=='DONE'">{{ mod.info.title }}</span>
                                    <input type="text" v-else v-model.trim="mod.info.title" class="w-400px" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 유/무상</th>
                                <td colspan="3" class="po-relative">
                                    <div v-if="mod.info.state=='DONE'" class="locked"></div>
                                    <label><input type="radio" v-model="mod.info.is_paid" value="N"><span class="ml-5 mr-20"> 무상</span></label>
                                    <label><input type="radio" v-model="mod.info.is_paid" value="Y"><span class="ml-5 mr-20"> 유상</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>무상 사유</th>
                                <td colspan="3">
                                    <span v-if="mod.info.state=='DONE'">{{ mod.info.unpaid_memo }}</span>
                                    <input type="text" v-else v-model.trim="mod.info.unpaid_memo" class="w-100per" maxlength="100">
                                </td>
                            </tr>
                            <tr>
                                <th>출고희망일</th>
                                <td colspan="3">
                                    <span v-if="mod.info.state=='DONE'">{{ mod.info.request_date }}</span>
                                    <carrot-date-picker v-else v-model="mod.info.request_date" class="w-110px"></carrot-date-picker>
                                </td>
                            </tr>
                            <tr>
                                <th>비고<br>(세부내용)</th>
                                <td colspan="3">
                                    <textarea v-model.trim="mod.info.etc_memo" class="w-100per h-100px" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- 외부 -->
                    <table class="table-row mt-40" style="display:none;">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="150">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>출판사</th>
                                <td colspan="3">
                                    캐럿글로벌
                                </td>
                            </tr>
                            <tr>
                                <th>배송 주소</th>
                                <td colspan="3">
                                    강남대로 488 대남빌딩 4층
                                </td>
                            </tr>
                            <tr>
                                <th>받는 분 성함</th>
                                <td>
                                    김민정
                                </td>
                                <th>연락처</th>
                                <td>
                                    010-0000-0000
                                </td>
                            </tr>
                            <tr>
                                <th>언어</th>
                                <td colspan="3">
                                    영어
                                </td>
                            </tr>
                            <tr>
                                <th>구입 사유</th>
                                <td colspan="3">
                                    구입 사유 내용
                                </td>
                            </tr>
                            <tr>
                                <th>구입거래처</th>
                                <td colspan="3">
                                    <select class="w-200px">
                                        <option value="">선택</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- //외부 -->

                    <button @click="mod.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">수정</button>
                    <!-- <button @click="mod.doCancel" class="btn-default float-right mt-30 mr-20">출고 완료</button> -->
                    <!-- <button @click="mod.doCancel" class="btn-default float-right mt-30 mr-20">출고 취소</button> -->
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import Swal from 'sweetalert2'


export default {
    layout:"ResourceManagement",
    components: {
        CarrotDatePicker,
        CarrotCompany,
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast();

        const mod = reactive({
            is_loaded  : false,
            utype_list : [],
            is_show    : true,

            idx : 0,

            info : {
                books : [
                    { idx_book:0, bookname:"", qty:0, price:"0", price_txt:"0" }
                ],
                utype             : "",
                btype_txt         : "내부",
                state_txt         : "출고",
                company           : "",
                title             : "",
                is_paid           : "N",
                unpaid_memo       : "",
                request_date      : "",
                etc_memo          : "",
            },

            doCancel : () => {
                router.go(-1);
                // router.push({ name:"ResourceManagement-TextbookRentalList" });
            },

            getBookRentalInfo : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/resourceManagement/getBookRentalInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        mod.utype_list = res.data.utype_list;

                        mod.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            addBooks : () => {
                mod.is_show = false;
                mod.info.books.splice(0, 0, { idx_book:0, bookname:"", qty:0, price:"0", price_txt:"0" });
                setTimeout(function(){
                    mod.is_show = true;
                }, 100);
            },

            delBooks : (i) => {
                mod.info.books.splice(i, 1);
            },

            calcPrice : (i) => {
                let o = mod.info.books[i];

                o.qty = o.qty.replace(/[^0-9]/gi, '');
                o.qty = (o.qty?Number(o.qty):0);

                if( o.qty > 0 ){
                    o.price     = o.qty*o.bookprice;
                    o.price_txt = (o.price).toLocaleString()
                } else {
                    o.price     = 0;
                    o.price_txt = 0;
                }

                mod.info.books[i] = o;
            },

            doSubmit : () => {
                let params = {
                    idx : mod.idx,
                    books        : mod.info.books,
                    utype        : mod.info.utype,
                    company      : mod.info.idx_company,
                    title        : mod.info.title,
                    is_paid      : mod.info.is_paid,
                    unpaid_memo  : mod.info.unpaid_memo,
                    request_date : mod.info.request_date,
                    etc_memo     : mod.info.etc_memo,
                };

                if( params.books.length <= 0 ){
                    toast.error("교재를 추가하세요.");
                    return;
                }
                for(let i=0; i<params.books.length; i++){
                    let irow = params.books[i];

                    if( irow.idx_book <= 0 ){
                        toast.error( (i+1) + "번째 교재를 선택하세요.");
                        return;
                    }
                    if( irow.qty <= 0 ){
                        toast.error( (i+1) + "번째 교재의 출고 수량을 입력하세요.");
                        return;
                    }
                }

                if( !params.utype ){
                    toast.error("용도를 선택하세요.");
                    return;
                }
                if( !params.company ){
                    toast.error("고객사를 선택하세요.");
                    return;
                }
                if( !params.title ){
                    toast.error("과정명을 입력하세요.");
                    return;
                }
                if( !params.is_paid ){
                    toast.error("유/무상 여부를 선택하세요.");
                    return;
                }
                if( params.is_paid == 'N' && !params.unpaid_memo ){
                    toast.error("무상 사유를 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/ckeckModRental", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '출고현황 수정',
                            text : '수정하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-TextbookRentalView-idx',
                                params : {
                                    idx : mod.idx
                                }
                            })
                        });
                    } else if ( res.data.err == 200 ){
                        Swal.fire({
                            title: '출고현황 수정',
                            text : '재고 수량을 초과했습니다. 그래도 수정 하시겠습니까?',
                            showCancelButton : true
                        }).then((res) => {
                            if( res.isConfirmed == true ){
                                axios.post("/rest/resourceManagement/modRental", params).then((res) => {
                                    if( res.data.err == 0 ){
                                        Swal.fire({
                                            title: '출고현황 수정',
                                            text : '수정하였습니다.'
                                        }).then(() => {
                                            router.push({
                                                name : 'ResourceManagement-TextbookRentalView-idx',
                                                params : {
                                                    idx : mod.idx
                                                }
                                            })
                                        });
                                    } else {
                                        if(res.data.err_msg) toast.error(res.data.err_msg);
                                    }
                                });
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx;
            if ( !mod.idx ) {
                router.go(-1);
            } 

            mod.getBookRentalInfo();

        });

        return {mod};
    }
}
</script>

<style lang="scss" scoped>
.locked {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2;
}
</style>