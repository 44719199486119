<template>
  <div class="carrot-datepicker">
    <v-datepicker v-model="comp.sdate" @dayclick="comp.update" class="datepicker-group">
      <template #default="{ inputValue, togglePopover }">
        <div>
          <input type="text" :value="inputValue" class="input w-80px h-30px" @click="togglePopover()" />
          <button class="btn-cal" @click="togglePopover()"></button>
        </div>
      </template>
    </v-datepicker>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import { onMounted, reactive, watch } from "vue";

export default {
  name: "CarrotDatePicker",
  components: {
    "v-datepicker": DatePicker,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const comp = reactive({
      sdate: "",

      update: () => {
        emit("update:modelValue", comp.dateToString());
        emit("change");
      },
      dateToString() {
        if (comp.sdate == null) {
          return "";
        }
        return [comp.sdate.getFullYear(), (comp.sdate.getMonth() + 1 > 9 ? "" : "0") + (comp.sdate.getMonth() + 1), (comp.sdate.getDate() > 9 ? "" : "0") + comp.sdate.getDate()].join("-");
      },
    });

    watch(
      () => props.modelValue,
      (nVal, oVal) => {
        if (nVal != oVal) {
          comp.sdate = nVal;
        }
      }
    );

    onMounted(() => {
      // Mounted
      comp.sdate = props.modelValue;
      // console.log(comp.sdate);
    });

    return { comp };
  },
};
</script>
<style lang="scss" scoped>
.dept-select {
  display: inline-block;
  select {
    width: 200px;
    margin-right: 10px;
  }
}
.carrot-datepicker {
  display: inline-block;
}
</style>
